import React from "react";
// importing Link from react-router-dom to navigate to
// different end points.
import $ from 'jquery';
import {Link} from 'react-scroll'
import mainlogo from "../../images/logo.png"
import banner1 from "../../images/banner.png"
import banner2 from "../../images/bna-img.png"
import banner3 from "../../images/banner-img2.png"
import socialicon1 from "../../images/social-icon1.png"
import socialicon2 from "../../images/social-icon2.png"
import socialicon3 from "../../images/social-icon3.png"
import homeimg1 from "../../images/home-img1.png"
import homeimg2 from "../../images/home-img2.png"
import homeimg3 from "../../images/home-img3.png"
import homeimg4 from "../../images/home-img4.png"
import homeimg5 from "../../images/home-img5.png"
import homeimg6 from "../../images/home-img6.png"
import homeimg7 from "../../images/home-img7.png"
import homeimg8 from "../../images/home-img8.png"
import homeimg9 from "../../images/home-img9.png"
import homeimg10 from "../../images/home-img10.png"
import homeimg11 from "../../images/home-img11.png"
import homeimg12 from "../../images/home-img12.png"
import homeimg13 from "../../images/home-img13.png"
import homeimg14 from "../../images/home-img14.png"
import homeimg15 from "../../images/home-img15.png"
import homeimg16 from "../../images/home-img16.png"
import homeimg17 from "../../images/home-img17.png"
import homeimg18 from "../../images/home-img18.png"
import homeimg19 from "../../images/footer-logo.png"

const Home = () => {
    // js start
    setTimeout(function() {
        $("body").addClass("banner-active");
    }, 1000);
    
  
    

return (

	<div>
       <header>
           <div className="container">
               <div className="row align-items-center">
                   <div className="col-lg-3 logo-sec">
                       <a href="/">
                           <img src={mainlogo} alt="" />
                       </a>
                   </div>
                   <div className="col-lg-9 menu-area">
                       <div id="navigation">
                           <nav>
                           <ul>
                             {/* <li><Link activeClass="active" to="about" spy={true} smooth={true}>About</Link></li>
                            <li><Link  to="mission" spy={true} smooth={true}>Mission</Link></li>
                            <li><Link  to="vision" spy={true} smooth={true}>Vision</Link></li>
                            <li><Link  to="segment" spy={true} smooth={true}>Customer Segment</Link></li>
                            <li><Link  to="features" spy={true} smooth={true}>features</Link></li>
                            <li><Link  to="connect" spy={true} smooth={true}>Connect</Link></li>  */}

                             <li className="current">
                                       <a href="#?">About</a>
                                   </li>
                                   <li>
                                       <a href="#?">Mission</a>
                                   </li>
                                   <li>
                                       <a href="#?">Vision</a>
                                   </li>
                                   <li>
                                       <a href="#?">Customer Segment</a>
                                   </li>
                                   <li>
                                       <a href="#?">Features</a>
                                   </li>
                                   <li>
                                       <a href="#?">Connect</a>
                                   </li> 
                            </ul>
                                  
                           </nav>
                       </div>
                   </div>
               </div>
           </div>
       </header>
    
               
                <div className="section banner-sec">
                     <img src={banner1} alt="" />
                     <img src={banner2} alt="" className="img1" />
                     <ul>
                         <li>
                             <a href="#">
                                <img src={socialicon1} alt="" />
                             </a>
                         </li>
                         <li>
                             <a href="#">
                                <img src={socialicon2} alt="" />
                             </a>
                         </li>
                         <li>
                             <a href="#">
                                <img src={socialicon3} alt="" />
                             </a>
                         </li>
                     </ul>
                     <div className="wrap">
                         <div className="container">
                             <div className="row align-items-center'">
                                 <div className="col-lg-6">
                                    <img src={banner3} alt="" className="img2" />
                                 </div>
                                 <div className="col-lg-6">
                                    <h2>Suscríbete para recibir información sobre la venta de token con antelación</h2>
                                    <form action="">
                                        <input type="text" placeholder="Enter your email" />
                                        <input type="submit" value="Loop me in!" />
                                    </form>
                                </div>
                             </div>
                         </div>
                     </div>
                </div>
                <div className="section body-cont1 " id="about">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 text-center">
                                <img src={homeimg1} alt=""/>
                                <h3>Empower</h3>
                                <p>Galaxy Guild is a blockchain-based Gaming 
                                    NFT lending and borrowing platform 
                                    powered by Solana. 
                                </p>
                            </div>
                            <div className="col-lg-4 text-center">
                            <img src={homeimg2} alt=""/>
                                <h3>Excel</h3>
                                <p>Galaxy Guild matches Gaming NFT lenders 
                                    and borrowers in an open, transparent and 
                                    decentralised marketplace and in doing so is allows the lending and borrowing of NFTs in 
                                    a trustless manner.
                                </p>
                            </div>
                            <div className="col-lg-4 text-center">
                            <img src={homeimg3} alt=""/>
                                <h3>Excite</h3>
                                <p>GG serves as the platform for Players, 
                                    Guilds, and Scholarship Managers to 
                                    collaborate and make the most out of their 
                                    Metaverse experience!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section body-cont2" id="mission">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 sec1">
                                
                                <h3>Mission</h3>
                                <p>GG serves as the platform for Players, 
                                    Guilds, and Scholarship Managers to collaborate and make the most out of their 
                                    Metaverse experience!
                                </p>
                                <img src={homeimg4} alt="" className="img1"/>
                            </div>
                            <div className="col-lg-6 sec2">
                                <img src={homeimg5} alt="" className="img2"/>
                                <img src={homeimg6} alt="" className="img3"/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section body-cont3" id="vision">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 sec1">
                                
                                <h3>Vision</h3>
                                <p>Galaxy Guild as the largest infrastructure platform to enable the financial democratisation of games. Powered by players, for the players
                                </p>
                                <img src={homeimg7} alt="" className="img1"/>
                            </div>
                            <div className="col-lg-6 sec2">
                                <img src={homeimg8} alt="" className="img2"/>
                                <img src={homeimg9} alt="" className="img3"/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section body-cont4" id="segment">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <h4>Customer Segments</h4>
                                <h3>Borrowers</h3>
                                <p>Galaxy Guild enables players to borrow gaming NFTs in a trustless manner.
                                </p>
                            </div>
                            <div className="col-lg-6 sec2">
                                <img src={homeimg10} alt="" className="img2"/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section body-cont4 bg1" id="segment">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <h4>Customer Segments</h4>
                                <h3>Borrowers</h3>
                                <p>Galaxy Guild enables players to borrow gaming NFTs in a trustless manner.
                                </p>
                                <h3>Lenders</h3>
                                <p>Galaxy Guild allows NFT owners to list their NFTs for lending and earn a yield.
                                </p>
                            </div>
                            <div className="col-lg-6 sec2">
                                <img src={homeimg11} alt="" className="img2"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section body-cont4 bg2" id="segment">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <h4>Customer Segments</h4>
                                <h3>Borrowers</h3>
                                <p>Galaxy Guild enables players to borrow gaming NFTs in a trustless manner.
                                </p>
                                <h3>Lenders</h3>
                                <p>Galaxy Guild allows NFT owners to list their NFTs for lending and earn a yield.
                                </p>
                                <h3>Guilds and Scholarship Managers</h3>
                                <p>Galaxy Guild gives existing Guilds and Scholarship Managers a medium to host their NFTs and give out their scholarships in a more trustless manner - allowing them to focus on building communities.
                                </p>
                            </div>
                            <div className="col-lg-6 sec2">
                                <img src={homeimg12} alt="" className="img2"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section body-cont4 bg3" id="segment">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <h4>Customer Segments</h4>
                                <h3>Borrowers</h3>
                                <p>Galaxy Guild enables players to borrow gaming NFTs in a trustless manner.
                                </p>
                                <h3>Lenders</h3>
                                <p>Galaxy Guild allows NFT owners to list their NFTs for lending and earn a yield.
                                </p>
                                <h3>Guilds and Scholarship Managers</h3>
                                <p>Galaxy Guild gives existing Guilds and Scholarship Managers a medium to host their NFTs and give out their scholarships in a more trustless manner - allowing them to focus on building communities.
                                </p>
                                <h3>Investors</h3>
                                <p>Galaxy Guild allows investors to gain exposure to P2E games by staking GG tokens in vaults and earning a yield on individual games sub-Guilds.
                                </p>
                            </div>
                            <div className="col-lg-6 sec2">
                                <img src={homeimg13} alt="" className="img2"/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section body-cont5" id="features">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                               <div className="sec1">
                                    <img src={homeimg14} alt="" className=""/>
                                    <h4>Player Focused Marketplace </h4>
                               </div>
                               <div className="sec2">
                                    <img src={homeimg15} alt="" className=""/>
                                    <h4>Bootstrap Engagement</h4>
                               </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="sec3">
                                            <img src={homeimg16} alt="" className=""/>
                                            <h4>Open for Guilds</h4>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="sec4">
                                            <img src={homeimg17} alt="" className=""/>
                                            <h4>Galaxy Guild vaults</h4>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="sec5">
                                            <img src={homeimg18} alt="" className=""/>
                                            <h4>Trust scoring System</h4>
                                        </div>
                                    </div>
                               </div>
                            </div>
                        </div>
                    </div>
                </div>

                <footer className="section" id="connect">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 text-center">
                               <img src={homeimg19} alt="" className=""/>
                               <a href="#" className="read-btn">Read Whitepaper</a>
                            </div>
                        </div>
                    </div>
                    <div className="sec1">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-3">
                                     <img src={mainlogo} alt="" className=""/>
                                </div>
                                <div className="col-lg-6">
                                    <h6>© GALAXY 2021 All right reserved</h6>
                                </div>
                                <div className="col-lg-3">
                                    <ul>
                                        <li>
                                            <a href="#">
                                                <img src={socialicon1} alt="" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <img src={socialicon2} alt="" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <img src={socialicon3} alt="" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
                
               
	</div>
);
};

export default Home;
